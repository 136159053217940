import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {CatalogueComponent} from './pages/catalogue/catalogue.component';
import {ProductComponent} from './pages/product/product.component';
import {SearchComponent} from './pages/search/search.component';
import {SubmittalComponent} from './pages/submittal/submittal.component';
import {ContentPageComponent} from './pages/content-page/content-page.component';
import {ResourceComponent} from './pages/resource/resource.component';

const routes: Routes = [
  {path: '', redirectTo: '/en', pathMatch: 'full'},
  {path: 'products', redirectTo: '/en/products', pathMatch: 'full'},
  {path: 'products/:product-id', redirectTo: '/en/products/:product-id', pathMatch: 'full'},
  {path: 'submittals', redirectTo: '/en/submittal', pathMatch: 'full'},
  {path: ':locale', component: HomeComponent},
  {path: ':locale/products', component: CatalogueComponent},
  {path: ':locale/products/:product-id', component: ProductComponent},
  {path: ':locale/search', component: SearchComponent},
  {path: ':locale/submittals', component: SubmittalComponent},
  {path: ':locale/resources', component: ResourceComponent},
  {path: ':locale/:page_slug', component: ContentPageComponent},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
